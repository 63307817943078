import { AssetAmountPair } from 'interfaces/AssetAmountPair.interface';
import { Balance, BalanceValue, CustodyTransactionsResponse, PendingTransaction } from './custodyApi';
import { emptySplitApi as api } from './emptyApi';
import { CreatedTransaction } from './investApi';
import { LoanTransactionType } from 'enums/LoanTransactionType.enum';

export const loanApi = api.injectEndpoints({
    endpoints: (build) => ({
        getLoanPendingTransactions: build.query<PendingTransaction[], string>({
            query: (loanProvider) => ({ url: `/custody/transactions/pending?loanProvider=${loanProvider}`}),
        }),
        getLoanTransactions: build.query<CustodyTransactionsResponse, DefiLoanTransactionArguments>({
            query: ({ page, loanProvider }) => ({
              url: `/custody/transactions?page=${page}&size=5&loanProvider=${loanProvider}`,
            }),
        }),
        createLoanTransactionRequest: build.mutation<CreateLoanTransactionResponse, CreateLoanTransactionRequest>({
            query: ({amount, currency, network, transactionType}) => ({
                url: `/custody/borrow/transaction/request`,
                method: 'POST',
                body: {
                    amount, 
                    currency,
                    network, 
                    transactionType
                }
            }),
        }),
        commitLoanTransaction: build.mutation<any, string>({
            query: (abraTxUid) => ({
                url: `/custody/borrow/transaction/confirm?abraTxUid=${abraTxUid}`,
                method: 'POST',
            }),
        }),
        getLoanBalances: build.query<LoanSummary, void>({
            query: () => ({url: `/custody/borrow/summary`}),
        }),
        getLoanInfo: build.query<LoanInfoResponse, LoanInfoRequest>({
            query: ({asset, network, transactionType}) => ({url: `/custody/borrow/transaction/info?asset=${asset}&network=${network}&transactionType=${transactionType}`}),
        }),
        getLoanHealth: build.query<LoanHealthResponse, LoanHealthRequest>({
            query: ({asset, network, amount, transactionType}) => {
                return ({url: `/custody/borrow/transaction/health-factor?asset=${asset}&network=${network}&transactionType=${transactionType}&amount=${amount}`})
            },
        }),
        getAvailableLoanAssets: build.query<CustodyBalanceResponse, string>({
            query: (type) => ({url: `/custody/borrow/asset/${type}`}),
        }),
        getLoans: build.query<any, void>({
            query: () => ({url: `/custody/borrow`}),
        }),
        getTxInformation: build.query<LoanInfoResponse, GetTxRequest>({
            query: ({asset, network, transactionType}) => ({ url: `/v1/loan/transaction/info?asset=${asset}&network=${network}&transactionType=${transactionType}` }),
        })
    }),
    overrideExisting: false,
});

interface CreateLoanTransactionRequest {
    amount: string;
    currency: string;
    network: string;
    transactionType: string;
}

interface CreateLoanTransactionResponse extends CreatedTransaction {}

export interface LoanSummary {
    accountUID: string;
    publicUID: string;
    provider: string;
    providerIdentifier: string;
    type: string;
    hasLoan: boolean;
    status: string;
    ltv: string;
    ltvWarningThreshold: string;
    ltvDangerThreshold: string;
    healthFactor: number;
    totalCollateralValue: AssetAmountPair;
    totalPrincipalValue: AssetAmountPair;
    collateralBalances: AssetAmountPair[];
    principalBalances: AssetAmountPair[]
}

export enum LoanStatus {
    INACTIVE = 'INACTIVE',
    WARNING = 'WARNING',
    ACTIVE = 'ACTIVE',
    LIQUIDATION = 'LIQUIDATION',
    COMPLETED = 'COMPLETED',
    ERROR = 'ERROR',
}

interface HealthFactor {
    value: number,
    status: string,
    isInfinity: boolean
}

interface LoanHealthRequest {
    asset: string; 
    network: string; 
    amount: string; 
    transactionType: string;
}

export interface PendingTransactionRequest {
    strategyIdentifier: string;
  }

export interface GetTxRequest {
    asset: string;
    network:string; 
    transactionType: LoanTransactionType;
}
  
export interface LoanHealthResponse {
    currentHealthFactor: HealthFactor; 
    nextHealthFactor: HealthFactor;
}
  
export interface LoanInfoResponse {
    availableAmountInAsset: AssetAmountPair,
    availableAmountValue: AssetAmountPair,
    totalAmountInAsset: AssetAmountPair,
    availableAmountInFeeAsset: AssetAmountPair,
    totalEstimatedFeeAmount: AssetAmountPair,
    totalEstimatedFeeInConvenience: AssetAmountPair,
    minimumTransactionAmount: AssetAmountPair,
    maxTransactionAmount: AssetAmountPair,
    network: string,
    networkIdentifier:string
}
  
export interface LoanInfoRequest {
    asset: string, 
    network: string, 
    transactionType: string
}

export interface CustodyBalanceResponse {
    custodyBalances?: CustodyBalanceDetail[]
    collateralBalances?: CustodyBalanceDetail[]
    availableForBorrowBalances?: CustodyBalanceDetail[]
    principalBalances?: CustodyBalanceDetail[]
}
  
export interface CustodyBalanceDetail {
    balance: Balance
    balanceValue: BalanceValue
    networkDescription: string
    network: string
    apy: string
}

export interface DefiLoanTransactionArguments {
    page: number | string;
    loanProvider: string;
}
  

export const {
    useGetLoanPendingTransactionsQuery,
    useCommitLoanTransactionMutation,
    useCreateLoanTransactionRequestMutation,
    useGetAvailableLoanAssetsQuery,
    useLazyGetAvailableLoanAssetsQuery,
    useGetLoanBalancesQuery,
    useLazyGetLoanBalancesQuery,
    useGetLoanHealthQuery,
    useGetLoanInfoQuery,
    useGetLoansQuery,
    useLazyGetLoansQuery,
    useGetTxInformationQuery,
    useGetLoanTransactionsQuery,
    useLazyGetLoanPendingTransactionsQuery
} = loanApi;