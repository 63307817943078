import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { MarginContainer } from '../Custody';
import Layout from 'components/templates/Layout';
import useCustodyRedirect from '../extraCustodyHooks/useCustodyRedirect';
import { BorrowPortfolio } from 'pages/DefiBorrow/BorrowPortfolio';

const theme = {
    colors: {
        primary: '#4f1a8d',
        secondary: '#3e146f80',
        text: '#ffffff'
    }
};




const LoanMainPage: React.FC = () => {
    useCustodyRedirect();
    

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <MarginContainer>
                  <BorrowPortfolio />
                </MarginContainer>
            </Layout>
        </ThemeProvider>
    );
};

export default LoanMainPage;
