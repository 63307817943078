
import { LoanTransactionType } from 'enums/LoanTransactionType.enum';
import Borrow from '/public/assets/png/Borrow_Private.png';
import DigitalAsset from '/public/assets/png/Digital_Asset_Private.png';
import Focus from '/public/assets/png/Focus_Private.png';
import Private from '/public/assets/png/Private_Private.png';

export const panelTitle: Record<number, string> = {
    0: "Loan with Abra",
    1: "Loan Dashboard",
    2: "Loan Dashboard"
}

export const stepsInstructions: Record<number, string[]> = {
    0: [],
    1: [
      "Choose the collateral you want to supply", 
      "Wait for the transaction to go through", 
      "Click 'next' button to go to borrow assets step"
    ],
    2: [
      "Choose assets you want to borrow", 
      "Wait for the transaction to go through", 
      "Click 'next' button to go to the loan dashboard"
    ],
    3: []
}

export interface CardData {
    icon: string;
    title: string;
    description: string;
}
  
export const cardData: CardData[] = [
    {
        icon: DigitalAsset,
        title: "Collateral LTV",
        description: "We calculate the BTC collateral needed based on your loan amount. (this is your loan-to-value ratio (LTV))."
    },
    {
        icon: Borrow,
        title: "Initiate Loan",
        description: "It takes ~2 business days to process the loan. Loan proceeds are available in either USDC or USD (via wire) to your bank account."
    },
    {
        icon: Focus,
        title: "Monitor Loan",
        description: "Abra tracks your loan's status and sends monthly statements showing interest, balance, and fees."
    },
    {
        icon: Private,
        title: "Loan Payoff",
        description: "Close your loan anytime. During the loan, you can repay it or liquidate collateral to cover the loan + interest."
    }
];

export type Operation = "add" | "remove"

export const transactionMapper: Record<string, Record<Operation, LoanTransactionType>> = {
    true: {
      add: LoanTransactionType.BORROW_PRINCIPAL,
      remove: LoanTransactionType.RETURN_PRINCIPAL
    },
    false: {
      add: LoanTransactionType.ADD_COLLATERAL,
      remove: LoanTransactionType.WITHDRAW_COLLATERAL
    }
}

export interface ButtonActionsByStep {
    [k: number] : {
      text: string,
      isDisabled: boolean
    }
}
  
export interface ButtonMapper {
    next: ButtonActionsByStep,
    back: ButtonActionsByStep,
}

export const ReviewModalMapper: Record<string, Record<Operation, string>> = {
    collateral: {
        add: "Supply",
        remove: "Withdraw"
    },
    principal: {
        add: "Borrow",
        remove: "Repay"
    }
}

export const isCollateralRemoveOrPrincipalBorrow = (
    assetType: string,
    operation: Operation
): boolean => {
    return (
        (assetType === 'collateral' && operation === 'remove') ||
        (assetType === 'principal' && operation === 'add')
    );
};

export const BalanceModalMapper: Record<string, Record<Operation, string>> = {
    collateral: {
        add: "Existing custody balance",
        remove: "Existing collateral balance"
    },
    principal: {
        add: "Existing borrow balance",
        remove: "Existing borrow balance"
    }
}

export const AaveInformationMapper: Record<string, Record<Operation, string>> = {
    collateral: {
        add: "You are supplying collateral to Aave to access liquidity from it.",
        remove: "You are withdrawing excess collateral from Aave."
    },
    principal: {
        add: "You are borrowing liquidity from Aave.",
        remove: "You are returning borrowed liquidity back to Aave."
    }
}

interface SectionMapperContent {
    title: string,
    mainOperationButtonTitle: string,
    placeholderCondition: boolean,
    placeholderText: string,
    disabledTooltip: string
}

export interface SectionMapper {
    suppliedCollateral: SectionMapperContent ,
    assetsBorrowed: SectionMapperContent,
    availableCollateral: SectionMapperContent,
    availableLoan: SectionMapperContent
}

export const loanTransactionMapper: Record<Operation, Record<string, LoanTransactionType>> = {
    add: {
        collateral: LoanTransactionType.ADD_COLLATERAL,
        principal: LoanTransactionType.BORROW_PRINCIPAL
    },
    remove: {
        collateral: LoanTransactionType.WITHDRAW_COLLATERAL,
        principal: LoanTransactionType.RETURN_PRINCIPAL
    }
}