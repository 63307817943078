import Layout from 'components/templates/Layout'
import Widget from 'components/templates/Widget'
import React from 'react'
import styled from 'styled-components'
import CustodyHeader from './CustodyHeader'
import CustodyInvestmentSummary from './CustodyInvestmentSummary'
import CustodyNavigationWidget from './CustodyNavigationWidget'
import CustodyPendingActivity from './CustodyPendingActivity'
import CustodyTokenSummary from './CustodyTokenSummary'
import CustodyTransactionDetails from './CustodyTransactionDetails'
import useCustodyRedirect from './extraCustodyHooks/useCustodyRedirect'
import useCustodyTerms from './useCustodyTerms'
import CustodyTermsOfService from './CustodyTermsOfService'
import usePermissions from 'shared/usePermissions'
import { palette } from 'lib/theme'

const Custody = () => {

  const { isCustodyInvestEnabled } = usePermissions();
  const { products } = useCustodyRedirect()

  useCustodyTerms()
  return (
  <Layout>
    {products && products.includes("Custody") &&
    <>
    <MarginContainer>
      <CustodyNavigationWidget>
        <CustodyHeader />
      </CustodyNavigationWidget>
    </MarginContainer>
    <MarginContainer>
      <Widget>
        <CustodyTokenSummary />
      </Widget>
    </MarginContainer>
    {isCustodyInvestEnabled ? (
      <MarginContainer>
        <Widget>
          <CustodyInvestmentSummary />
        </Widget>
      </MarginContainer>
    ) : null}
    <MarginContainer>
      <Widget>
        <CustodyPendingActivity />
      </Widget>
    </MarginContainer>
    <MarginContainer>
      <Widget minHeight='650px'>
        <CustodyTransactionDetails />
      </Widget>
    </MarginContainer>
    <CustodyTermsOfService />
    </>}
  </Layout>
  )
}

export const MarginContainer = styled.div`
  display: flex;
  margin-top: 15px;
  width: 100%;
  @media print {
    background-color: white !important;
  }
`

const ActionButton =  styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #6F2ACD;
  border-radius: 0.5vw;
  padding: 0.5vw 1vw;
  width: 8vw;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #292749;
  }
`

export const ErrorMessage = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  color: #dadada;
  line-height: 0.7rem;
  padding: 10px;
  border-radius: 10px;
  background: ${palette.red.error};
`;
export const WarningMessage = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  color: #dadada;
  line-height: 0.7rem;
  padding: 10px;
  border-radius: 10px;
  background: #E9992899;
`;
export default Custody
