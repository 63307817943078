import React from 'react';
import styled from 'styled-components';
import { CustodyClickableText, CustodyMainText, CustodyStyledTable } from './CustodyStyles';
import usePermissions from 'shared/usePermissions';
import { useNavigate } from 'react-router-dom';
import useInvest from './invest/useInvest';
import { DefiBalanceRow } from 'state/store/investApi';
import { Info, QuestionCircle } from 'assets/icons';
import getUsDollar from 'shared/dollarFormat';
import { Percentage } from 'lib/utils/types';
import Tooltip from 'components/atoms/Tooltip';
import InkindAssetRenderer from "../../atoms/asset/InkindAssetRenderer";
import CustodyRateOfReturns from './CustodyRateOfReturns'
import { isNegative } from 'utils/isNegative';

interface CustodyInvestmentSummaryProps {
  includeAllBalances?: boolean;
}
const CustodyInvestmentSummary: React.FC<CustodyInvestmentSummaryProps> = ({ includeAllBalances }) => {
  const invest = useInvest();
  const balances = invest.defiBalances.data
    ?.filter(b => includeAllBalances || b.currentBalance) ?? [];
  return (
    <Container>
      <TokenSummaryTitle>Investment Details</TokenSummaryTitle>
      {!invest.defiBalances.isLoading && balances.length === 0 && <CustodyMainText>You don't have any investments. Please go to the invest tab to learn more about the strategies Abra offers</CustodyMainText>}
      {balances.length > 0 && 
      <CustodyStyledTable>
        <thead>
          <tr>
            <th>Strategy</th>
            <th>Yield</th>
            <th>Current Balance</th>
            <th>Cost Basis</th>
            <Tooltip placement='top' text='Any unrealized gain/ loss prior to Abra deposit is not accounted for'>
              <UnrealizedGainLossTitle>
                Unrealized Gain / Loss <Info color='#fffa' size={15} />
              </UnrealizedGainLossTitle>
            </Tooltip>
            <th>Rate of Return</th>
            <th>Income (YTD)</th>
            <th>Realized Gain / Loss (YTD)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {balances?.map((record, index) => (
            <CustodySummaryRow key={index} record={record} />
          ))}
        </tbody>
      </CustodyStyledTable>
      }
    </Container>
  );
};

interface CustodySummaryRowProps {
  record: DefiBalanceRow;
}
const CustodySummaryRow: React.FC<CustodySummaryRowProps> = ({ record }) => {
  const { canInvest, canDivest } = usePermissions();
  const navigate = useNavigate();

  const goToStrategyDetails = (strategyIdentifier: string) => {
    navigate(`/custody/invest/details/${strategyIdentifier}`);
  };

  const goToInvest = (strategyIdentifier: string) => {
    navigate(`/custody/invest/${strategyIdentifier}`);
  };

  const goToDivest = (strategyIdentifier: string) => {
    navigate(`/custody/divest/${strategyIdentifier}`);
  };

  const renderInvestButton = canInvest && record.canInvest;
  const renderDivestButton = canDivest && record.canDivest && !!record.currentBalance;
  const getYieldText = () => {
    let prefix = 'Yield is calculated as an annualized APR based on the ';
    switch (record.yield.method) {
      case 'rolling7':
        return prefix + 'last 7 days of actual interest';
      case 'rolling30':
        return prefix + 'last 30 days of actual interest';
      case 'rolling7income':
        return prefix + 'actual interest paid out over last 7 days';
      case 'rolling30income':
        return prefix + 'actual interest paid out over last 30 days';
      case 'rolling30price':
        return prefix + 'price change over the last 30 days';
      case 'rolling12months':
        return prefix + 'actual interest paid out over last 12 months or max period available';
      case 'rolling52weeks':
        return prefix + 'actual interest paid out over last 52 weeks or max period available';
      case 'rolling365days':
        return prefix + 'actual interest paid out over last 365 days or max period available';
      case 'lastday':
        return prefix + 'actual interest for last day.';
      case 'lastweek':
        return prefix + 'actual interest paid last week';
      case 'lastmonth':
        return prefix + 'actual interest paid out last month';
      case 'lastfourweeks':
        return prefix + 'actual interest paid out in the last 4 weeks';
      default:
        return '';
    }
  }

  return (
    <tr>
      <td onClick={() => goToStrategyDetails(record.identifier)}>
        <CustodyClickableText>{record.strategyName}</CustodyClickableText>
      </td>
      <td>
        <CustodyMainText>
          {record.yield.method ? (<Tooltip text={getYieldText()} placement='top'>
            <HoverArea >
              {new Percentage(record.yield?.apr).print()} <QuestionCircle color='#fffc' />
            </HoverArea>
          </Tooltip>) : <>
            {new Percentage(record.yield?.apr).print()}
          </>}
        </CustodyMainText>
      </td>
      <td>
      
        <CustodyMainText>
          <InkindAssetRenderer
              balance={record.currentBalance}
              value={record.currentValue}
          />
        </CustodyMainText>
        
      </td>
      <td>
        <CustodyMainText isNegative={isNegative(record.costBasis?.amount)}>{getUsDollar(record.costBasis?.amount ?? '0')}</CustodyMainText>
      </td>
      <td>
        <GainLossArea loss={parseFloat(record.unrealizedAmount?.amount ?? '0') < 0}>
          <CustodyMainText isNegative={isNegative(record.unrealizedAmount?.amount)}>{getUsDollar(record.unrealizedAmount?.amount ?? '0')}</CustodyMainText>
        </GainLossArea>
      </td>
      <td>
        <CustodyMainText>
          <CustodyRateOfReturns defiBalanceRow={record}/>
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          <InkindAssetRenderer
            balance={record.yieldYTD}
            value={record.incomeYTD}
          />
        </CustodyMainText>
      </td>
      <td>
      <GainLossArea loss={parseFloat(record.realizedYTD?.amount ?? '0') < 0}>
        <CustodyMainText isNegative={isNegative(record.realizedYTD?.amount)}>{getUsDollar(record.realizedYTD?.amount ?? '0')}</CustodyMainText>
      </GainLossArea>
      </td>
      <td>
        <ButtonsArea>
          {renderInvestButton && (
            <ActionButton onClick={() => goToInvest(record.identifier)}>
              <span>Invest</span>
            </ActionButton>
          )}
          {renderDivestButton && (
            <ActionButton onClick={() => goToDivest(record.identifier)}>
              <span>Divest</span>
            </ActionButton>
          )}
        </ButtonsArea>
      </td>
    </tr>
  );
};

interface GainLossProps {
  loss: boolean;
}
const GainLossArea = styled.span<GainLossProps>`
  ${(p) => (p.loss ? 'color: #EC396B;' : '')}
`;
const UnrealizedGainLossTitle = styled.th`
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
`;
const HoverArea = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #6f2acd;
  border-radius: 0.5vw;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  font-size: 70%;
  padding: 4px 1vw;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #292749;
  }
`;
const ButtonsArea = styled.div`
  display: flex;
  gap: 0.5vw;
`;
const Container = styled.div`
`
const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-bottom: 6px;
`;
export default CustodyInvestmentSummary;
